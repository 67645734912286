import React from "react";
import * as styles from "./index.module.scss";
import PageTitle from "../utils/PageTitle";
import MembersContainer from "./MembersContainer";
import Filter from "../utils/Filter";

export default function index({ members, teamCategories }) {
  const categoryNamesArray = members.map(member => member.node.frontmatter.category).flat()
	const categoriesWithMembers = [...new Set(categoryNamesArray)]
	const categoriesWithMembersMapped = teamCategories.filter(category => categoriesWithMembers.includes(category.node.frontmatter.name))
	const catNames = categoriesWithMembersMapped.map(c => {
		return {
			name: c.node.frontmatter.name,
			displayName: c.node.frontmatter.title
		} 
	});
	catNames.unshift({name: 'all', displayName: "All"})
	const catNamesWithAll = catNames

  return (
    <div className="page-wrapper">
      <header className={`page-section-top`}>
        <div className="section-container section-container--withFilter">
          <PageTitle title="Our Team" color="green" />
        </div>
      </header>
      <section className={`${styles.members} section-container relative flex`}>
        <MembersContainer members={members} categories={catNamesWithAll}/>
      </section>
      <div
          className={`${styles.filterContainer} filter-container absolute flex pl-0 lg:fixed`}
        >
          <Filter
            categories={catNamesWithAll}
          />
        </div>
    </div>
  );
}
