/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./index.module.scss";
import { connect } from "react-redux";
import { setModalContent, toggleModal } from "../../../state/actions";
import { TweenMax, Sine } from "gsap";
import { Flipped } from "react-flip-toolkit";

class Member extends Component {
  constructor(props) {
    super(props);
    this.member = null;
    this.height = null;
    this.memberHeight = null;
    this.state = { height: 0 };
  }

  componentDidMount() {
    const { order } = this.props;

    this.setMembersHeight();

    if (this.props.firstRendered === true) {
      TweenMax.fromTo(
        this.member,
        0.5,
        { opacity: 0, y: 10 },
        { opacity: 1, y: 0, ease: Sine.easeOut }
      ).delay(order * 0.1);
    }

    if (this.member) {
      window.addEventListener("resize", this.setMembersHeight, false);
    }
  }

  componentWillUnmount() {
    if (this.member) {
      window.removeEventListener("resize", this.setMembersHeight, false);
    }
  }

  setMembersHeight = () => {
    if (this.member) {
      const height = (this.member.clientWidth * 13) / 15;
      this.setState({
        height: height,
      });
    }
  };

  handleClick = () => {
    const { openModal, node, updateContent, categories } = this.props;

    const categoriesIncluded = categories
      .filter((c) => this.props.node.frontmatter.category.includes(c.name))
      .map((c) => c.displayName);

    openModal(true);
    updateContent({
      title: node.frontmatter.name,
      position: node.frontmatter.title,
      text: node.frontmatter.description,
      facebook: node.frontmatter.facebook,
      twitter: node.frontmatter.twitter,
      linkedin: node.frontmatter.linkedin,
      image: node.frontmatter.background || node.frontmatter.image,
      categories: categoriesIncluded,
    });
  };

  onElementAppear = (el, index) => {
    TweenMax.fromTo(
      el,
      0.8,
      { autoAlpha: 0 },
      { autoAlpha: 1, ease: Sine.easeOut }
    ).delay(index * 0.05);
  };

  render() {
    const { image, name, title } = this.props.node.frontmatter;

    return (
      <Flipped
        flipId={`item-${name}`}
        translate
        opacity
        onAppear={this.onElementAppear}
      >
        <article
          className={`${styles.container} relative cursor-pointer`}
          ref={(member) => (this.member = member)}
          style={{ height: this.state.height }}
          onClick={this.handleClick}
        >
          <div className={`${styles.stroke} w-full h-full`} />
          <div className={`${styles.strokeGreen} lg:hidden`} />
          <div className={styles.inner}>
            {image && (
              <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                loading="eager"
                alt=""
              />
            )}
          </div>
          <div
            className={`${styles.filter} w-full h-full bg-brand-black opacity-0 lg:hidden`}
          >
            <div
              className={`${styles.content} w-full px-4 text-center opacity-0`}
            >
              <span
                className={`${styles.name} block uppercase font-title brand-white`}
              >
                {name || "TONY ZANDER"}
              </span>
              <span
                className={`${styles.title} block uppercase italic brand-grey`}
              >
                {title || "VP, PRODUCT"}
              </span>
            </div>
          </div>
        </article>
      </Flipped>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateContent: (content) => dispatch(setModalContent(content)),
    openModal: (isOpenned) => dispatch(toggleModal(isOpenned)),
  };
};

export default connect(null, mapDispatchToProps)(Member);
