import React, { Component } from "react";
import Member from "../Member";
import loadStates from "../../../hocs/loadStates";
import { Flipper } from "react-flip-toolkit";

class MembersContainer extends Component {
  constructor(props) {
    super(props);
    this.filterMember = this.filterMember.bind(this);
    this.myElements = [];
    this.state = { firstRendered: true };
    this.memberContainer = null
  }

  getFilteredCategory() {
    return this.props.categoryFilter.toFilter;
  }

  filterMember(category, members) {
    return members.filter((member) =>
      member.node.frontmatter.category.includes(category)
    );
  }

  componentDidMount() {
    this.setState({ firstRendered: false });
  }

  render() {
    let members = this.props.members;
    const { categories } = this.props;
    const curCat = this.getFilteredCategory();

    members = curCat === "all" ? members : this.filterMember(curCat, members);

    return (
      <Flipper flipKey={this.getFilteredCategory()}>
        <div
          className={`flex overflow-hidden py-10 lg:flex-wrap lg:m-auto`}
          ref={container => this.memberContainer = container}
        >
          {members &&
            members.map((member, i) => (
              <Member
                key={`member-${i}`}
                order={i}
                firstRendered={this.state.firstRendered}
                categories={categories}
                {...member}
              />
            ))}
        </div>
      </Flipper>
    );
  }
}

export default loadStates(MembersContainer);
