import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Team from "../components/Team";

const IndexPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <SEO title="Our Team" />
      <Team
        members={data.members.edges}
        teamCategories={data.teamCategories.edges}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`query MemberQuery {
  members: allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "team"}}}
    sort: {fields: frontmatter___order, order: ASC}
  ) {
    edges {
      node {
        frontmatter {
          title
          name
          image {
            childImageSharp {
              gatsbyImageData(quality: 50, layout: FULL_WIDTH)
            }
          }
          background {
            childImageSharp {
              gatsbyImageData(quality: 50, layout: FULL_WIDTH)
            }
          }
          description
          category
          facebook
          linkedin
          twitter
        }
      }
    }
  }
  teamCategories: allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "team-category"}}}
    sort: {fields: frontmatter___order, order: ASC}
  ) {
    edges {
      node {
        frontmatter {
          name
          title
        }
      }
    }
  }
}
`;
